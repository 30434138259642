import { useState, useEffect } from "react";
import { css } from "styled-components";
import Slider from "react-slick";

import { SlickStyles } from "@nested/ui";

const carouselWrapper = css`
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;
`;

/*
 * We need to re-render the slick carousel when the DOM is
 * loaded as the responsive settings are NOT converted
 * to media queries by react-slick during server-side rendering,
 * which results in ugly layout bugs on screen sizes included in
 * the responsive breakpoints.
 */

export const Carousel = ({ settings, sliderRef, children }) => {
  const [key, setKey] = useState("initial-key");

  useEffect(() => {
    setKey("dom-loaded-key");
  }, []);

  return (
    <div css={carouselWrapper}>
      <SlickStyles />
      <Slider {...settings} ref={sliderRef} key={key}>
        {children}
      </Slider>
    </div>
  );
};
