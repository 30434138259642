// @flow
import { useRef } from "react";
import { css } from "styled-components";
import { useSelector } from "react-redux";
import { media } from "@nested/brand";
import { SlickStyles } from "@nested/ui";
import { Button, ButtonLink } from "@nested/component-library";
import { fetchForSaleProperties } from "../ForSaleList/ducks";
import { type PropertyType } from "./Property";
import { Carousel } from "./Carousel";
import {
  regularHeading,
  sectionHeading,
  largeHeading,
} from "../../components/Typography/Headings";
import { whiteSliceAdjacentSliceRulesNoSidePadding } from "../AdjacentSliceRules/AdjacentSliceRules";

import { LeftArrow, RightArrow } from "../../icons";

const backgroundStyle = css`
  background-color: white;
  ${whiteSliceAdjacentSliceRulesNoSidePadding}
`;

const wrapperStyle = css`
  text-align: center;
`;

const headerStyles = css`
  padding: 0px 20px;
`;

const heading6Style = css`
  ${sectionHeading}
  margin: 0 auto;
`;

const heading3Style = css`
  ${regularHeading}
  margin: 0 auto 30px auto;
  white-space: pre-wrap;
  ${media.tablet`
    ${largeHeading}
    margin: 0 auto 40px auto;
  `}
  ${media.desktop`
    margin: 10px auto 60px auto;
  `}
`;

const buttonWrapperStyle = css`
  display: inline-flex;
  margin-top: 30px;
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    margin-top: 60px;
  `}
`;

const buttonLinkStyle = css`
  margin: 0px 10px;
  ${media.tablet`
    margin: 0px 20px;
  `}
`;

const arrowStyle = css`
  width: 24px;
  display: block;
  ${media.tablet`
    width: 38px;
 `}
`;

const noCarouselPadding = css`
  padding-top: 40px;
  ${media.desktop`
    padding-top: 60px;
  `}
`;

type Props = {
  buttonLabel: string,
  heading: string,
  repeat?: { outcode?: string }[],
  localLandingPageCarousel?: boolean,
};

export const filterOutcodes = (
  properties: PropertyType[],
  repeat?: { outcode?: string }[],
): PropertyType[] => {
  if (!repeat) {
    return properties;
  }

  const outcodes = repeat.filter((o) => o.outcode).map((o) => o.outcode);
  if (outcodes.length === 0) {
    return properties;
  }

  return properties.filter(
    ({ address: { outcode } }) => outcodes.indexOf(outcode) !== -1,
  );
};

const PropertyCarousel = ({ buttonLabel, heading, repeat }: Props) => {
  const slider = useRef();
  const allProperties = useSelector(
    (state) => state.forSalePage.forSaleProperties || [],
  );

  const properties = filterOutcodes(allProperties, repeat);

  if (properties.length < 4) {
    return <div css={noCarouselPadding} />;
  }

  return (
    <>
      <div css={backgroundStyle} className="optional-top-padding">
        <SlickStyles />
        <div css={wrapperStyle}>
          <div css={headerStyles}>
            <h6 css={heading6Style}>For Sale</h6>
            <h3 css={heading3Style}>{heading}</h3>
          </div>

          <Carousel propertyList={properties} sliderRef={slider} />
          <div css={buttonWrapperStyle}>
            <Button
              noText
              onClick={() => {
                // $FlowFixMe
                slider.current.slickPrev();
              }}
            >
              <LeftArrow css={arrowStyle} />
            </Button>
            <ButtonLink css={buttonLinkStyle} to="/for-sale">
              {buttonLabel}
            </ButtonLink>
            <Button
              noText
              onClick={() => {
                // $FlowFixMe
                slider.current.slickNext();
              }}
            >
              <RightArrow css={arrowStyle} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

PropertyCarousel.fetchData = fetchForSaleProperties;

export { PropertyCarousel };
