import styled from "styled-components";
import { sectionHeading } from "@nested/brand";

const StatusBubble = styled.div`
  margin-left: 10px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme, status }) =>
    status === "Sold STC" ? theme.palette.yellow100 : theme.palette.pink100};
`;

const StatusText = styled.p`
  ${sectionHeading}
  font-size: 10px;
  margin: 0;
  padding: 0px 10px;
  position: relative;
  top: 1px;
`;

export const statusMapping = {
  UNDER_OFFER: "Under offer",
  SOLD: "Sold STC",
};

export function propertyStatus(status) {
  return statusMapping[status] || null;
}

export function PropertyStatus({ status }) {
  return statusMapping[status] ? (
    <StatusBubble status={statusMapping[status]}>
      <StatusText>{statusMapping[status]}</StatusText>
    </StatusBubble>
  ) : null;
}
