// @flow

export function formatPrice(price: string | number): string {
  if (typeof price === "number") {
    if (price % 1 === 0 || price % 1 < 0.01) {
      return price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
    return price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return price;
}

export function formatPriceWithKOrM(price: number): string {
  if (price > 999999) {
    return formatPriceWithM(price);
  }

  return formatPriceWithK(price);
}

function formatPriceWithK(price: number): string {
  const formattedPrice = formatPrice(price);
  const regex = /(^.*)(,.*)$/gi;
  return formattedPrice.replace(regex, "$1K");
}

function formatPriceWithM(price: number): string {
  const priceInMillions = price / 1000000;
  const formattedPriceInMillions = priceInMillions.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
  return `${formattedPriceInMillions}M`;
}
