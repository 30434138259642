import { theme } from "../theme";

export const LeftArrow = ({ fill = theme.palette.hague, className }) => (
  <svg className={className} viewBox="0 0 36 10" version="1.1">
    <g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <path
        d="M0,4.584 L0,4.824 C2.304,5.808 4.128,7.584 5.712,9.408 L6.192,8.952 C5.64,8.16 4.944,7.392 4.224,6.648 C4.008,6.432 3.888,6.168 3.888,5.976 C3.888,5.736 4.08,5.496 4.512,5.496 L35.136,5.496 L35.136,3.912 L4.488,3.912 C4.248,3.912 3.864,3.624 3.864,3.384 C3.864,3.19885714 3.93453061,3.06661224 4.10581924,2.88146939 L4.2,2.784 C4.896,2.112 5.64,1.248 6.168,0.456 L5.688,0 C4.128,1.8 2.304,3.624 0,4.584 Z"
        fill={fill}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
