// @flow
import { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { media } from "@nested/brand";
import { getImage, formatPrice } from "@nested/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faTag } from "@fortawesome/free-solid-svg-icons";
import { smallParagraph } from "../../components/Typography/Paragraphs";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { PropertyStatus } from "./PropertyStatus";

function formattedStreet(street) {
  return street.replace(/,/g, "").replace(/\W+/g, "-");
}

function detailsUrl(address, externalId) {
  return `/for-sale/${address.outcode}/${formattedStreet(
    address.street,
  )}/${externalId}`;
}

const imageStyle = css`
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const imageAspectWrapper = css`
  width: 100%;
  padding-top: calc(100% * 2 / 3);
  position: relative;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.sand20};
`;

const propertyWrapperStyle = css`
  text-align: center;
  padding: 0px 10px;
  ${media.tablet`
    text-align: left;
    padding: 0px 20px;
  `}
`;

const addressStyle = css`
  ${smallParagraph}
  font-weight: 500;
  margin: 20px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 21px;
`;

const iconWrapperStyle = css`
  ${smallParagraph}
  margin: 0 10px;
  ${media.tablet`
    margin: 0px 20px 0px 0px;
  `}
`;

const linkToProperty = css`
  text-decoration: none;
  display: block;
  cursor: pointer;
`;

const statusWrapper = css`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export type PropertyType = {|
  externalId: string,
  image: string,
  bedrooms: number,
  address: {|
    borough: string,
    lat: number,
    lng: number,
    outcode: string,
    street: string,
  |},
  price: number,
  status: string,
|};

export const Property = ({
  externalId,
  image,
  bedrooms,
  address,
  price,
  status,
}: PropertyType) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <div
      ref={ref}
      data-test="for-sale-carousel:property-card-container"
      css={propertyWrapperStyle}
    >
      <a css={linkToProperty} href={detailsUrl(address, externalId)}>
        <div css={imageAspectWrapper}>
          <ResponsiveImage
            inView={inView}
            css={imageStyle}
            src={getImage(`property-listings/${externalId}/${image}`)}
            alt={address.street}
            className="chromatic-ignore"
          />
          <div css={statusWrapper}>
            <PropertyStatus status={status} />
          </div>
        </div>
        <p css={addressStyle}>
          {address.street}, {address.outcode}
        </p>
        {bedrooms && (
          <span css={iconWrapperStyle}>
            <FontAwesomeIcon color="#9db3bb" icon={faBed} /> {bedrooms}
          </span>
        )}
        <span css={iconWrapperStyle}>
          <FontAwesomeIcon color="#9db3bb" icon={faTag} /> {formatPrice(price)}
        </span>
      </a>
    </div>
  );
};
