// @flow
import { Property, type PropertyType } from "./Property";
import { Carousel as CarouselComponent } from "../../components/Carousel/Carousel";

const settings = {
  touchMode: false,
  swipe: false,
  centerMode: true,
  slidesToShow: 2,
  centerPadding: "20%",
  lazyLoad: true,
  initialSlide: 3,
  responsive: [
    {
      breakpoint: 479,
      settings: {
        centerMode: true,
        variableWidth: false,
        centerPadding: "10px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 860,
      settings: {
        variableWidth: false,
        centerMode: false,
        centerPadding: "20px",
        slidesToShow: 2,
      },
    },
  ],
};

type Props = {|
  propertyList: PropertyType[],
  sliderRef: any,
|};

export const Carousel = ({ propertyList, sliderRef }: Props) => {
  return (
    <CarouselComponent settings={settings} sliderRef={sliderRef}>
      {propertyList.map((property, index) => (
        <Property {...property} key={`property_${index}`} />
      ))}
    </CarouselComponent>
  );
};
