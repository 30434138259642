import { theme } from "../theme";

export const RightArrow = ({ fill = theme.palette.hague, className }) => (
  <svg className={className} viewBox="0 0 36 10" version="1.1">
    <g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <path
        d="M35.136,4.584 L35.136,4.824 C32.832,5.808 31.008,7.584 29.424,9.408 L28.944,8.952 C29.496,8.16 30.192,7.392 30.912,6.648 C31.128,6.432 31.248,6.168 31.248,5.976 C31.248,5.736 31.056,5.496 30.624,5.496 L0,5.496 L0,3.912 L30.648,3.912 C30.888,3.912 31.272,3.624 31.272,3.384 C31.272,3.19885714 31.2014694,3.06661224 31.0301808,2.88146939 L30.936,2.784 C30.24,2.112 29.496,1.248 28.968,0.456 L29.448,0 C31.008,1.8 32.832,3.624 35.136,4.584 Z"
        fill={fill}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
